export default (values) => {
  const errors = {};

  if (!values.date) errors.date = true;
  if (!values.duration) errors.duration = true;
  if (!values.time) errors.time = true;

    
  return errors;
};
