import { resetFilter } from '../State/filter';
import { resetSelectedMonth } from '../State/selectedMonth';
import { resetActiveTab } from '../State/activeTab';
import { clearLessons } from '../State/lessons';

export default () => async (dispatch) => {
  
  dispatch(resetFilter());
  dispatch(resetSelectedMonth());
  dispatch(resetActiveTab());
  dispatch(clearLessons());
}