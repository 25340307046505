import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Component from './Component';


import onClickCreate from './Handlers/onClickCreate';
import onClickCancel from './Handlers/onClickCancel';

import onSubmit from '../../Form/onSubmit';
import onSubmitFail from '../../Form/onSubmitFail';
import validate from '../../Form/validate';

const mapStateToProps = (state) => ({
  student: state.screens.StudentEditor.student
});

const actionCreators = {
  onClickCreate,
  onClickCancel
};

const formConfig = {
  form: "Students",
  onSubmit,
  onSubmitFail,
  validate,
  shouldValidate: () => true
};

export default connect(mapStateToProps, actionCreators)(reduxForm(formConfig)(Component));
