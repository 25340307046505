import loadingIndicator from '../../resources/images/loading.gif';
import loginLogo from '../../resources/images/login-logo.png';
import headerLogo from '../../resources/images/header-logo.png';

const darkgrey = '#3C3B3B';
const blue = '#1990FF';

export default {

  header: {
    barColor: darkgrey,
    logo: headerLogo
  },

  startup: {
    loadingIndicator
  },

  login: {
    brandingPanelColor: darkgrey,
    brandingPanelLogo: loginLogo,
    inputBorderColorFocused: blue,
    submitButtonColor: blue
  }

}