import { createAction, handleActions } from 'redux-actions';

const loadLessons = createAction("SCREENS/LESSONS/LOAD_LESSONS");
const clearLessons = createAction("SCREENS/LESSONS/CLEAR_LESSONS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadLessons]: (state, action) => action.payload,
    [clearLessons]: () => defaultState
  },
  defaultState
);

export { loadLessons, clearLessons, reducer };