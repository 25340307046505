import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Form from './Form';

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    nextOfKins: PropTypes.object,

    componentWillMount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    nextOfKins: null
  }

  componentWillMount = () => this.props.componentWillMount(this.props);


  render () {

    const {
      submitting,

      onClickCancel,
      onClickSubmit,
      nextOfKins

    } = this.props;

    return (        
      <Modal
        title={`${nextOfKins ? 'Update Next of Kins': 'New Next of Kins'}`}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={`${nextOfKins ? 'Update' : 'Create'}`}
      >

        <Form />

      </Modal>

    )
  }
}