import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state)
});

const actionCreators = {
  componentWillMount,
  onClickCancel,
  onClickSubmit
};

export default connect(mapStateToProps, actionCreators)(Component);