import { getProps } from '../props';
import create from '../Services/create';
import update from '../Services/update';

export default async (values, dispatch) => {
  
  const { onClose, didCreate, didUpdate} = getProps();
  
  if (!values.id) {

    const isCreated = await dispatch(create(values));

    if (isCreated) {

      onClose();

      if (didCreate) didCreate();

    }

  } else {
    
    const isUpdated = await dispatch(update(values));

    if (isUpdated) {
      
      onClose();

      if (didUpdate) didUpdate();

    }

  }

}