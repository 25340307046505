import { createAction, handleActions } from 'redux-actions';

const setLessonForEdit = createAction("SCREENS/LESSON_BLOCK_VIEW/SET_LESSON_FOR_EDIT");

const defaultState = null;

const reducer = handleActions(
  {
    [setLessonForEdit]: (state, action) => action.payload
  },
  defaultState
);

export { setLessonForEdit, reducer };
