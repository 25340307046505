import { createSelector } from 'reselect';

const selectStudent = state => state.screens.StudentView.student;
const selectSearchTerm = state => state.screens.StudentView.searchTerm;
const selectActiveTab = state => state.screens.StudentView.activeTab;

export default createSelector(
  selectStudent,
  selectSearchTerm,
  selectActiveTab,
  (student, searchTerm, activeTab) => {
    
    if (activeTab === "lessons" && searchTerm) {
      return Object.assign({}, student, { 
        lessons: student ? student.lessons.filter(lessons => lessons.name.toLowerCase().includes(searchTerm.toLowerCase())) : []
      });
    }

    if (activeTab === "next_of_kins" && searchTerm) {
      return Object.assign({}, student, { 
        next_of_kins: student ? student.next_of_kins.filter(nextOfKins => (
          nextOfKins.first_name.toLowerCase() +' '.concat(nextOfKins.last_name.toLowerCase())).includes(searchTerm.toLowerCase())
        ) : []
      });
    }
    
    return student;

  }
)