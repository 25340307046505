import * as React from 'react';
import PropTypes from 'prop-types';

import LessonBlockFormInput from './LessonBlockFormInput';

export default class extends React.PureComponent{

  static propTypes = {
    disciplines: PropTypes.array.isRequired,
    tutors: PropTypes.array.isRequired,
    submitting: PropTypes.bool.isRequired,
    dayOfWeek: PropTypes.string,
    paid: PropTypes.string,

    handleSubmit: PropTypes.func.isRequired,
    onChangePaid: PropTypes.func.isRequired
  }

  static defaultProps = {
    dayOfWeek: null,
    paid: null
  }

  render() {
    const {
      disciplines,
      tutors,
      submitting,
      dayOfWeek,
      paid,
      handleSubmit,
      onChangePaid
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>

        <LessonBlockFormInput
          disciplines={disciplines}
          tutors={tutors}
          submitting={submitting}
          dayOfWeek={dayOfWeek}
          paid={paid}
          lessonBlock={null}
          onChangePaid={onChangePaid}
        />

        <button type="submit" className="ant-btn ant-btn-primary" style={{ position: 'absolute', bottom: 10, right: 16, zIndex: 2 }}>
          Next
        </button>
        
      </form>
      
    )
  };
}
