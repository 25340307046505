import { createAction, handleActions } from 'redux-actions';

const setShowLessonDetailsModal = createAction("SCREENS/LESSON/SET_SHOW_LESSON_DETAILS_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowLessonDetailsModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowLessonDetailsModal, reducer };
