import { message } from 'antd'
import handleException from '../../../Core/Helpers/handleException';

import { updateLesson } from '../../../HttpRequests/lessons';


export default (values) => async () => {
  try {
    
    const data = {...values};

    await updateLesson({ id: values.id, data });

    message.success("Lesson Updated.");

    return true;

  } catch(error) {
    
    handleException(error);

    return false;
  }
}