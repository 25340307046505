import { createAction, handleActions } from 'redux-actions';

const loadTutors = createAction("CORE/CLIENTS/LOAD_TUTORS");

const defaultState = []

const reducer = handleActions(
  {
    [loadTutors]: (state, action) => action.payload
  },
  defaultState
);

export { loadTutors, reducer };