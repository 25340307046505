import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { NavLink } from 'react-router-dom';

export default class extends React.PureComponent{

  static propTypes = {
    students: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    onChangeTable: PropTypes.func.isRequired
  }

  render () {
    
    const {
      students,
      pagination,
      onChangeTable
    } = this.props;

    const columns = [
      { 
        title: 'Name', 
        dataIndex: '',
        width: 200,
        render: (text, record) => (
          <NavLink to={`/portal/students/${record.id}/view`}>
            { `${record.first_name} ${record.last_name}` }
          </NavLink>
        )
      }
    ];

    return (

      <Table 
        dataSource={students}
        columns={columns} 
        rowKey={record => record.id}
        onChange={onChangeTable}
        pagination={{          
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
      />

    )
  };
}