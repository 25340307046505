import { message } from 'antd';
import handleException from '../../../Core/Helpers/handleException';
import { createLesson } from '../../../HttpRequests/lessons';


export default (values) => async () => {
  try {
    
    const data = {...values};
    await createLesson({ data });

    message.success("Lesson created.")

    return true;

  } catch(error) {
    
    handleException(error);

    return false;
  }
}