import { message } from 'antd';

import { updateLessonBlock } from '../../../HttpRequests/lessonBlocks';
import handleException from '../../../Core/Helpers/handleException';
import { setIsFormFilledUp } from '../State/isFormFilledUp';

export default (values) => async (dispatch) => {
  
  try {
    
    await updateLessonBlock({ id: values.id, data: values });
    
    message.success("Lesson Block Updated")
    
    dispatch(setIsFormFilledUp(false));
    
    return true;

  } catch(error) {

    handleException(error);

    return false;

  }
}
