import { change} from 'redux-form';
import moment from 'moment';

import { setIsFormFilledUp } from '../State/isFormFilledUp';
import { FORM_NAME } from '../Form'

export default () => async (dispatch, getState) => {

  const formValue = getState().form.LessonBlock.values;
  
  const lessons = [];
  
  if (formValue && (formValue.dates && formValue.day_of_week)) {

    const dates = formValue.dates.split(',');
    const startDate = moment(dates[0]);
    const endDate = moment(dates[1]);

    const clone = startDate.clone().day(formValue.day_of_week);

    lessons.push({
      date: startDate.format('YYYY-MM-DD'),
      checked: true
    });

    while(clone.isBefore(endDate)) {
      clone.add(7, 'days');

      lessons.push({
        date: clone.format('YYYY-MM-DD'),
        checked: true
      });

    }
  }

  dispatch(change(FORM_NAME, 'lessons', lessons));

  dispatch(setIsFormFilledUp(true));

}