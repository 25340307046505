import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Input } from 'antd';

export default class extends React.PureComponent{

  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    onSubmitSearch: PropTypes.func.isRequired
  }

  render () {
    const {
      searchTerm,
      onClickNew,
      onChangeSearch,
      onSubmitSearch
    } = this.props;
    
    return (

      <Row>
    
        <Col span="12">
          <h1>
            Lessons
          </h1>
        </Col>

        <Col span="12" className="text-right">

          <Input.Search
            style={{ width: 250 }}
            className="m-r-10"
            placeholder="Search"
            onChange={onChangeSearch}
            onSearch={onSubmitSearch}
            value={searchTerm}
          />
          
          <Button type="primary" onClick={onClickNew}>
            New Lesson Block
          </Button>

        </Col>
        
      </Row>
      
    )
  }
}