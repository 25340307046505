import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table, Button } from 'antd';
import moment from 'moment';

export default class extends React.PureComponent{

  static propTypes = {
    lessonBlock: PropTypes.object.isRequired,
    
    onClickDelete: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired
  }

  render() {
    const {
      lessonBlock,
      onClickDelete,
      onClickNew,
      onClickEdit
    } = this.props

    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        width: '30%',
        render: (text) => (
          <span>
            {moment(text).format('DD MMM YYYY')}
          </span>
        )
      },

      {
        title: 'Time',
        dataIndex: 'time',
        width: '15%',
      },

      {
        title: 'Duration',
        dataIndex: 'duration',
        width: '15%',
        render: (text) =>  (
          <span>
            {`${text} ${text === 1 ? 'Hour': 'Minutes'}`}
          </span>
        )
        
      },

      {
        title: 'Cancelled',
        dataIndex: '',
        width: '30%',
        render: (text, record) => (
          <span>
            {record.cancelled ? 'Yes' : 'No'}
          </span>
        )
      },
      

      {
        title: 'Actions',
        dataIndex: '',
        width: '10%',
        render: (text, record) => (
          <React.Fragment>
            <Button icon="edit" style={{ marginRight: 5 }} onClick={() => onClickEdit(record)} />
            <Button icon="delete" type="danger" onClick={() => onClickDelete(record)} />
          </React.Fragment>
        )
      }
    ]
    return (
      <React.Fragment>

        <Row style={{ marginBottom: 5, marginTop: 15}}>

          <Col span={12}>
            <h2>
              Lessons
            </h2>
          </Col>
          
          <Col span={12} style={{ textAlign: 'right'}}>
            <Button type="primary" onClick={onClickNew}>
              New Lesson
            </Button>
          </Col>
        </Row>

        <Table
          dataSource={lessonBlock.lessons}
          columns={columns}
          rowKey={record => record.id}
        />
      </React.Fragment>
    )
  };
}