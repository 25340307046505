import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd'
import moment from 'moment'
import styles from './Details.css';

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object.isRequired
  }

  render() {

    const { student } = this.props;

    const address = [
      student.address_line_1,
      student.address_line_2,
      student.address_line_3,
      student.town_or_city,
      student.postcode,
      student.county
    ].filter(line => line).join(', ');

    const dateOfBirth = moment(student.date_of_birth);

    return (
      <Card>
        
        <Row>
          
          <Col span={12}>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Name :
              </div>

              <div className={styles.detailData}>

                { student.first_name+' '.concat(student.last_name) }

              </div>

            </div>


            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Date of Birth :
              </div>

              <div className={styles.detailData}>

                { `${dateOfBirth.format('DD MMM YYYY')} (${moment().diff(dateOfBirth, 'years')} years old)` }

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Gender :
              </div>

              <div className={styles.detailData}>

                { student.gender }

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Note :
              </div>

              <div className={styles.detailData}>

                { student.notes || '---' }

              </div>

            </div>

          </Col>

          <Col span={12}>
            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Email :
              </div>

              <div className={styles.detailData}>

                { student.email || '---'}

              </div>

            </div>


            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Mobile :
              </div>

              <div className={styles.detailData}>

                { student.mobile || '---' }

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Home :
              </div>

              <div className={styles.detailData}>

                { student.home || '---' }

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                School :
              </div>

              <div className={styles.detailData}>

                { student.school || '---' }

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Address:
              </div>

              <div className={styles.detailData}>

                { address || '---' }

              </div>

            </div>

          </Col>

        </Row>
        
      </Card>
    )
  };
}