import * as React from 'react';
import PropTypes from 'prop-types';
import { Screen } from 'evolve-portal';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import StudentForm from './Components/StudentForm';

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object,
  
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired
  }

  static defaultProps = {
    student: null
  }

  componentDidMount = () => this.props.componentDidMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render() {
    
    const {
      student
    } = this.props;
    
    return (

      <Screen>

        <Breadcrumbs student={student} />

        <Header student={student} />
        
        <StudentForm />

      </Screen> 

    )
  }
}