import { connect } from 'react-redux';

import Component from './Component';

import onClickNew from './Handlers/onClickNew';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';

const mapStateToProps = (state) => ({
  searchTerm: state.screens.Students.searchTerm  
});

const actionCreators = {
  onClickNew,
  onChangeSearch,
  onSubmitSearch
};

export default connect(mapStateToProps, actionCreators)(Component);
