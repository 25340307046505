import { combineReducers } from 'redux';

import { reducer as lessonBlocks } from './State/lessonBlocks';
import { reducer as pagination } from './State/pagination';
import { reducer as searchTerm } from './State/searchTerm';

export default combineReducers({
  lessonBlocks,
  pagination,
  searchTerm
})
