import { createSelector } from 'reselect';

const selectPermissions = () => [];
const selectPathname = state => state.router.location.pathname;

export default createSelector(
  selectPermissions,
  selectPathname,
  (permissions, pathname) => {

    const left = [];
    const right = [];

    left.push({
      key: "timetable",
      title: 'Timetable',
      href: '/portal/timetable',
      selected: pathname && pathname.startsWith('/portal/timetable')
    });

    left.push({
      key: "students",
      title: 'Students',
      href: '/portal/students',
      selected: pathname && pathname.startsWith('/portal/students')
    });

    left.push({
      key: "lesson_blocks",
      title: 'Lessons',
      href: '/portal/lesson_blocks',
      selected: pathname && pathname.startsWith('/portal/lesson_blocks')
    });

    return { left, right };
  }
)