import { getProps } from '../props';

import handleException from '../../../Core/Helpers/handleException';
import { getLessonBlock } from '../../../HttpRequests/lessonBlocks'
import { loadLessonBlock } from '../State/lessonBlock';

export default () => async (dispatch) => {

  try {

    const { id } = getProps().match.params;
    
    const params = {}

    params.include = [
      'student',
      'lessons.discipline',
      'lessons.tutor'
    ].join();

    const response = await getLessonBlock({ params, id })

    dispatch(loadLessonBlock(response.data.data));

  } catch (error) {

    handleException(error);

  }
}