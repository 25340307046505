import { createAction, handleActions } from 'redux-actions';

const loadStudent = createAction("SCREENS/STUDENT_VIEW/LOAD_STUDENT");
const appendtoNextOfKins = createAction("SCREENS/STUDENT_VIEW/APPEND_TO_NEXT_OF_KINS");
const clearStudent = createAction("SCREENS/STUDENT_VIEW/CLEAR_STUDENT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadStudent]: (state, action) => action.payload,
    [appendtoNextOfKins]: (state, action) => Object.assign({}, state, { 
      next_of_kins: [...state.next_of_kins, action.payload] 
    }),
    [clearStudent]: () => null
  },
  defaultState
);

export { loadStudent, appendtoNextOfKins, clearStudent, reducer };