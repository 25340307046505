import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { nextOfKins, student } = getProps();

  if (!nextOfKins) {

    dispatch(initialize(FORM_NAME, {
      student_id: student.id
    }));

  } else {
    
    const data = {...nextOfKins};
    data.student_id = student.id;
    
    dispatch(initialize(FORM_NAME, data));

  }
}