/* eslint-disable react/no-unescaped-entities */

import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { groupBy } from 'lodash';

import { getColDimension, getNumberOfCells } from '../Utils';
import styles from './PlotLessons.css';

export default class extends React.PureComponent{
  
  static propTypes = {
    lessons: PropTypes.any.isRequired,
    colDimensions: PropTypes.any.isRequired,
    onClickViewDetails: PropTypes.func.isRequired
  }

  componentDidMount = () => getColDimension();
  
  render() {
    const {
      lessons,
      colDimensions,
      onClickViewDetails
    } = this.props;
    
    const groupLessons = groupBy(lessons, 'day_of_week');

    const arrayLessons = Object.values(groupLessons);

    const itemDimention = {
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
      'Sunday': 7,
    }

    if (!colDimensions) return null;

    return (

      <React.Fragment>

        {
          arrayLessons.map((value, valueIndex) => {
            
            const groupByTime = groupBy(value, 'time');
            const arrayLessonTime = Object.values(groupByTime);

            const allLessons = arrayLessonTime.map((lessonTime) => {

              const firstLesson = lessonTime[0];

              const { left, width } = colDimensions[itemDimention[firstLesson.day_of_week]]
              const { time, duration } = firstLesson;
              
              const endTime = moment(time, 'hh:mm:ss').add(duration, duration === 1 ? 'hour' : 'minutes').format('hh:mm A');

              const start = getNumberOfCells(moment(time, 'hh:mm:ss').format('hh:mm A'));
              const end = getNumberOfCells(endTime);
              
              const rowHeight = 12.5;
              const top = start * rowHeight;
              const height = (end - start) * rowHeight;

              return (
                <div
                  className={
                    classnames(styles.item, {
                      [styles.cancelled]: lessonTime.filter(lesson => lesson.cancelled && !lesson.attended).length === lessonTime.length,
                      [styles.attended]: lessonTime.filter(lesson => !lesson.cancelled && lesson.attended).length === lessonTime.length
                    })
                  }
                  style={{
                    width,
                    height,
                    left,
                    top,
                  }}
                  key={firstLesson.id}
                  data-time={time}
                  data-duration={duration}
                  onClick={() => onClickViewDetails(lessonTime)}
                  role="presentation"
                >
                  <div className={styles.student}>
                    
                    {lessonTime.length === 1 && `${firstLesson.student.first_name} ${firstLesson.student.last_name}`}

                    {lessonTime.length > 1 && lessonTime.map(lesson => lesson.student.first_name).join(', ')}
                    
                  </div>
                  
                </div>
              )

            });

            return (
              <React.Fragment key={String(valueIndex)}>
                {allLessons}
              </React.Fragment>
            )

          })
        }

      </React.Fragment>

    )
  }
}