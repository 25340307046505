import { combineReducers } from 'redux';

import { reducer as student } from './State/student';
import { reducer as activeTab } from './State/activeTab';
import { reducer as searchTerm } from './State/searchTerm';

import { reducer as showNextOfKinsModal } from './State/showNextOfKinsModal';
import { reducer as nextOfKinsForEdit } from './State/nextOfKinsForEdit';
import { reducer as showLessonBlockModal } from './State/showLessonBlockModal';

export default combineReducers({
  student,
  activeTab,
  searchTerm,
  
  showNextOfKinsModal,
  nextOfKinsForEdit,
  showLessonBlockModal
})
