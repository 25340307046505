import { connect } from 'react-redux';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onCloseLessonBlockModal from './Handlers/onCloseLessonBlockModal';
import didUpdateLessonBlock from './Handlers/didUpdateLessonBlock';

import onCloseLessonModal from './Handlers/onCloseLessonModal';
import didCreateLesson from './Handlers/didCreateLesson';
import didUpdateLesson from './Handlers/didUpdateLesson';

const mapStateToProps = (state) => ({
  lessonBlock: state.screens.LessonBlockView.lessonBlock,
  showLessonBlockModal: state.screens.LessonBlockView.showLessonBlockModal,
  showLessonModal: state.screens.LessonBlockView.showLessonModal,
  lessonForEdit: state.screens.LessonBlockView.lessonForEdit
});

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseLessonBlockModal,
  didUpdateLessonBlock,
  onCloseLessonModal,
  didCreateLesson,
  didUpdateLesson
};

export default connect(mapStateToProps, actionCreators)(Component);