import { reduxForm } from 'redux-form';

import Form from '../Components/Form';

import validate from './validate';
import onSubmit from './onSubmit';

export const FORM_NAME = 'Lesson';

const formConfig = {
  form: FORM_NAME,
  validate,
  onSubmit
}

export default reduxForm(formConfig)(Form);
