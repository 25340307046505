import { message } from 'antd';
import handleException from '../../../Core/Helpers/handleException';
import { createNextofKin } from '../../../HttpRequests/nextOfKins';

export default (values) => async () => {
  try {
    
    const data = {...values};
    await createNextofKin({ data });

    message.success("New Next of Kins Created.")
    
    return true;

  } catch(error) {
    
    handleException(error);
    return false;
  }
}