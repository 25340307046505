import { message } from 'antd';
import { push } from 'connected-react-router';

import { deleteStudent } from '../../../HttpRequests/students';
import handleException from '../../../Core/Helpers/handleException';

export default (id) => async (dispatch) => {

  try {  
    
    await deleteStudent({ id });

    dispatch(push(`/portal/students`));
    
    message.success("Student deleted")

  } catch (error) {

    handleException(error);

  }
}