import validate from './validate';
import onSubmit from './onSubmit';

export const FORM_NAME = 'LessonBlock';

export const FORM_CONFIG = {
  form: FORM_NAME,
  validate,
  onSubmit,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
}