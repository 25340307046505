import { createAction, handleActions } from 'redux-actions';

const loadTutor = createAction("SCREENS/LESSONS/LOAD_TUTOR");
const clearTutor = createAction("SCREENS/LESSONS/CLEAR_TUTOR");

const defaultState = [];

const reducer = handleActions(
  {
    [loadTutor]: (state, action) => action.payload,
    [clearTutor]: () => defaultState
  },
  defaultState
);

export { loadTutor, clearTutor, reducer };