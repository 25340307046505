import * as React from 'react';
import PropTypes from 'prop-types';
import { Screen } from 'evolve-portal';
import { Tabs } from 'antd';

import NextOfKinsModal from '../../Modals/NextOfKins';
import LessonBlockModal from '../../Modals/LessonBlock';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import NextOfKins from './Components/NextOfKins';
import LessonsBlocks from './Components/LessonsBlocks';

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object,
    nextOfKinsForEdit: PropTypes.object,
    showNextOfKinsModal: PropTypes.bool.isRequired,
    showLessonBlockModal: PropTypes.bool.isRequired,

    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseNextOfKinsModal: PropTypes.func.isRequired,
    didCreateNextOfKins: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onCloseLessonBlockModal: PropTypes.func.isRequired,
    didCreateLessonBlock: PropTypes.func.isRequired
  }

  static defaultProps = {
    student: null,
    nextOfKinsForEdit: null
  }

  componentDidMount = () => this.props.componentDidMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render() {

    const {
      student,
      showNextOfKinsModal,
      nextOfKinsForEdit,
      showLessonBlockModal,
    
      onCloseNextOfKinsModal,
      didCreateNextOfKins,
      onChangeActiveTab,
      onCloseLessonBlockModal,
      didCreateLessonBlock
    } = this.props;

    const { TabPane } = Tabs;

    if (!student) return null;
    return (

      <Screen>

        <Breadcrumbs student={student} />

        <Header />

        <Tabs
          defaultActiveKey='LESSON_BLOCKS'
          className="p-t-10"
          onChange={onChangeActiveTab}
          animated={false}
        >
          <TabPane tab="Lesson Blocks" key="LESSON_BLOCKS">

            <LessonsBlocks />
            
          </TabPane>

          <TabPane tab="Next of Kins" key="NEXT_OF_KINS">
            
            <NextOfKins />
            
          </TabPane>

        </Tabs>

        {showNextOfKinsModal && (

          <NextOfKinsModal
            onClose={onCloseNextOfKinsModal}
            didCreate={didCreateNextOfKins}
            student={student}
            nextOfKins={nextOfKinsForEdit}
          />

        )}

        {showLessonBlockModal && (
          <LessonBlockModal
            onClose={onCloseLessonBlockModal}
            didCreate={didCreateLessonBlock}
            student={student}
          />
        )}
      </Screen> 

    )
  }
}