import { Modal } from 'antd';
import deleteLesson from '../../../Services/deleteLesson';

const { confirm } = Modal;

export default (props: any) => async (dispatch) => {

  confirm({
    title: `Are you sure you want to delete this Lesson?`,
    content: 'Note: This action cannot be undone',
    okText: 'Yes, Delete it',
    onOk() {

      const { id } = props
      
      dispatch(deleteLesson({ id }));
      
    }
  });

}