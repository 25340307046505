import { createAction, handleActions } from 'redux-actions';

const setShowNextOfKinsModal = createAction("SCREENS/STUDENT_VIEW/SET_SHOW_NEXT_OF_KINS_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowNextOfKinsModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowNextOfKinsModal, reducer };
