import { createAction, handleActions } from 'redux-actions';

const setIsFormFilledUp = createAction("SCREENS/STUDENT_VIEW/SET_IS_FORM_FILLED_UP");

const defaultState = false;

const reducer = handleActions(
  {
    [setIsFormFilledUp]: (state, action) => action.payload
  },
  defaultState
);

export { setIsFormFilledUp, reducer };
