import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd'
import moment from 'moment';

import styles from './Details.css';

export default class extends React.PureComponent{

  static propTypes = {
    lessonBlock: PropTypes.object.isRequired
  }

  render() {
    
    const { lessonBlock } = this.props;
    const { lessons }  = lessonBlock;

    return (
      <Card>

        <Row>

          <Col span={12}>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Title :
              </div>

              <div className={styles.detailData}>

                { lessonBlock.title}

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Discipline :
              </div>

              <div className={styles.detailData}>

                { lessons.length > 0 ? lessons[0].discipline.name : '--'}

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Tutor :
              </div>

              <div className={styles.detailData}>

                { lessons.length > 0 ? `${lessons[0].tutor.first_name} ${lessons[0].tutor.last_name}` : '--'}

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Start Date :
              </div>

              <div className={styles.detailData}>

                { lessons.length > 0 ? moment(lessons[0].date).format('DD MMM YYYY') : '--'}

              </div>

            </div>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                End Date :
              </div>

              <div className={styles.detailData}>

                { lessons.length > 0 ? moment(lessons[lessons.length - 1].date).format('DD MMM YYYY') : '--'}

              </div>

            </div>


          </Col>

          <Col span={12}>

            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Day of Week :
              </div>

              <div className={styles.detailData}>

                { lessons.length > 0 ? lessons[0].day_of_week : '--' }

              </div>

            </div>
            
            <div className={styles.detailWrapper}>

              <div className={styles.detailLabel}>
                Paid :
              </div>

              <div className={styles.detailData}>

                { lessonBlock.paid === 1 ? 'Yes' : 'No'}

              </div>

            </div>

            {lessonBlock.paid === 1 && (
              <React.Fragment>
                <div className={styles.detailWrapper}>

                  <div className={styles.detailLabel}>
                    Paid Date :
                  </div>

                  <div className={styles.detailData}>

                    { moment(lessonBlock.paid_date).format('DD MMM YYYY')}

                  </div>

                </div>

                <div className={styles.detailWrapper}>

                  <div className={styles.detailLabel}>
                    Paid Method :
                  </div>

                  <div className={styles.detailData}>

                    { lessonBlock.paid_method }

                  </div>

                </div>
                
              </React.Fragment>
            )}
          </Col>

        </Row>

      </Card>
    )
  };
}