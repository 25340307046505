import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';

export default class extends React.PureComponent{

  static propTypes = {
    lessonBlock: PropTypes.object.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired
  }

  render () {

    const {
      lessonBlock,
      onClickEdit,
      onClickDelete
    } = this.props;
    
    return (

      <Row>
    
        <Col span={12}>
          <h1>
            { `${lessonBlock.student.first_name} ${lessonBlock.student.last_name} - ${lessonBlock.title}` }
          </h1>
        </Col>

        <Col span={12} className="text-right">
          
          <Button type="primary" onClick={onClickEdit}>
            Edit Lesson Block
          </Button>
          <Button type='danger' onClick={() => onClickDelete(lessonBlock)} style={{ marginLeft: 10}}>
            Delete Lesson Block
          </Button>
        </Col>
        
      </Row>

    )
  }
}