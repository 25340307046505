import { message } from 'antd';
import { push } from 'connected-react-router';

import { deleteLessonBlock } from '../../../HttpRequests/lessonBlocks';
import handleException from '../../../Core/Helpers/handleException';

export default (props) => async (dispatch) => {

  try {

    const { id, student } = props;
    
    await deleteLessonBlock({ id });

    dispatch(push(`/portal/students/${student.id}/view`));
    
    message.success("Lesson Block deleted")

  } catch (error) {

    handleException(error);

  }
}