import { connect } from 'react-redux';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onChangeWeek from './Handlers/onChangeWeek';
import onChangeActiveTab from './Handlers/onChangeActiveTab';
import onCloseLessonDetailsModal from './Handlers/onCloseLessonDetailsModal';
import didUpdateSelectedLesson from './Handlers/didUpdateSelectedLesson';

const mapStateToProps = (state) => ({
  tutors: state.screens.Timetable.tutors,
  selectedLessons: state.screens.Timetable.selectedLessons,
  showLessonDetailsModal: state.screens.Timetable.showLessonDetailsModal
});

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onChangeWeek,
  onChangeActiveTab,
  onCloseLessonDetailsModal,
  didUpdateSelectedLesson
};

export default connect(mapStateToProps, actionCreators)(Component);