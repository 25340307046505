import { initialize } from 'redux-form';

import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { lessonBlock } = getProps();

  if (!lessonBlock) {

    const { student } = getProps();

    dispatch(initialize(FORM_NAME, {
      student_id: student.id,
      lessons: [],
      duration: '30'
    }));

  } else {

    const { student } = lessonBlock;

    const { lessons }   = lessonBlock;

    const dayOfWeek = lessons.length > 0 ? lessons[0].day_of_week : '';
    const duration  = lessons.length > 0 ? lessons[0].duration : '30';
    const time      = lessons.length > 0 ? lessons[0].time : '';
    const startDate = lessons.length > 0 ? lessons[0].date : '';
    const endDate   = lessons.length > 0 ? lessons[lessons.length - 1].date : '';
    

    dispatch(initialize(FORM_NAME, {

      id: lessonBlock.id,
      student_id: student.id,
      title: lessonBlock.title,
      discipline_id: lessonBlock.lessons[0].discipline_id,
      tutor_id: lessonBlock.lessons[0].tutor_id,
      day_of_week: dayOfWeek.toString(),
      duration,      
      time,
      dates: `${startDate},${endDate}`,
      paid: lessonBlock.paid,
      paid_method: lessonBlock.paid_method,
      paid_date: lessonBlock.paid_date,
      notes: lessonBlock.notes

    }));
  }
}