import { message } from 'antd';
import { push } from 'connected-react-router';

import handleException from '../../../Core/Helpers/handleException';
import { createStudent } from '../../../HttpRequests/students';

export default ({ data }) => async (dispatch) => {
  
  try{
    
    await createStudent({ data });

    message.success("New Student Created.");

    dispatch(push('/portal/students'));

  } catch(error) {
    
    handleException(error)

  }
}