import * as React from 'react';
import PropTypes from 'prop-types';
import { Screen } from 'evolve-portal';
import { Tabs, DatePicker } from 'antd';

import Breadcrumbs from './Components/Breadcrumbs';

import WeekView from './Components/WeekView';
import LessonDetails from '../../Modals/LessonDetails';

export default class extends React.PureComponent{

  static propTypes = {
    tutors: PropTypes.array.isRequired,
    selectedLessons: PropTypes.array.isRequired,
    showLessonDetailsModal: PropTypes.bool.isRequired,

    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onChangeWeek: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onCloseLessonDetailsModal: PropTypes.func.isRequired,
    didUpdateSelectedLesson: PropTypes.func.isRequired
  }

  componentDidMount = () => this.props.componentDidMount();

  componentWillUnmount = () => this.props.componentWillUnmount();
  
  render() {
    const {
      tutors,
      selectedLessons,
      showLessonDetailsModal,
      onChangeWeek,
      onChangeActiveTab,
      onCloseLessonDetailsModal,
      didUpdateSelectedLesson
    } = this.props;
    
    if (tutors.length === 0) return null;

    return (
      <Screen>
        
        <Breadcrumbs />

        <div>

          <Tabs
            defaultActiveKey={tutors[0].id.toString()}
            animated={false}
            onChange={onChangeActiveTab}
            tabBarExtraContent={(
              <DatePicker
                onChange={onChangeWeek}
              />
            )}
          >
            {
              tutors.map((tutor) => (
                <Tabs.TabPane tab={`${tutor.first_name} ${tutor.last_name}`} key={tutor.id.toString()}>

                  <WeekView />

                </Tabs.TabPane>
              ))
            }
          
          </Tabs>
        </div>
        
        {showLessonDetailsModal && (
          <LessonDetails
            selectedLessons={selectedLessons}
            onClose={onCloseLessonDetailsModal}
            didUpdate={didUpdateSelectedLesson}
          />
        )}
      </Screen>
    )
  };
}