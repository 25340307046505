import * as React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Divider } from 'antd';
import { NavLink } from 'react-router-dom';

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object.isRequired
  }

  render() {

    const { student } = this.props;
    const { Item } = Breadcrumb;

    return (
      
      <React.Fragment>

        <Breadcrumb separator="»">

          <Item>
            Home
          </Item>

          <Item>
            
            <NavLink to="/portal/students">
              Students
            </NavLink>

          </Item>

          <Item>

            { student.first_name+' '.concat(student.last_name) }

          </Item>

        </Breadcrumb>
          
        <Divider />

      </React.Fragment>

    )
  };
}