import createStudent from '../Services/createStudent';
import updateStudent from '../Services/updateStudent';

export default async (values, dispatch, props) => {
  
  const data = { ...values }
  
  if (!props.student) {

    await dispatch(createStudent({ data }));

  } else {
   
    const { id } = props.student;
    await dispatch(updateStudent({ id, data }))

  }

};
