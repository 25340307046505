import { sortBy } from 'lodash'

import handleException from '../../../Core/Helpers/handleException';

import { getLessons } from '../../../HttpRequests/lessons';
import { loadLessons } from '../State/lessons';
import { setFetching } from '../State/fetching';

import computeDimension from '../Components/WeekView/Handlers/computeDimension';

export default () => async (dispatch, getState) => {

  dispatch(setFetching(true));

  try {
    
    const { activeTab, filter } = getState().screens.Timetable;
    const params = {};
    params.include = ['student', 'discipline', 'tutor', 'lesson_block'].join();

    params['filter[tutor_id]'] = activeTab;

    if (filter) {
      params['filter[date_between]'] = filter
    }

    params.sort =  'date';
    
    const response = await getLessons({ params })
        
    const lessons = sortBy(response.data.data, lesson =>  lesson.day_of_week )

    dispatch(loadLessons(lessons));

    dispatch(computeDimension())

  } catch(error) {

    handleException(error);

  }

  dispatch(setFetching(false));
}