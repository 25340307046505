import { initialize } from 'redux-form';

import handleException from '../../../Core/Helpers/handleException';
import { getStudent } from '../../../HttpRequests/students';
import { loadStudent } from '../State/student';

export default ({ id }) => async (dispatch) => {
  try{

    const response = await getStudent({ id });
    const student = response.data.data;
    dispatch(loadStudent(response.data.data));

    dispatch(initialize('Students', { ...student }))
    
  } catch(error) {
    
    handleException(error);
    
  }
}