import { combineReducers } from 'redux';

import { reducer as students } from './State/students';
import { reducer as pagination } from './State/pagination';
import { reducer as searchTerm } from './State/searchTerm';

export default combineReducers({
  students,
  pagination,
  searchTerm
})
