import { Modal } from 'antd';

import deleteLessonBlock from '../../../Services/deleteLessonBlock';

const { confirm } = Modal;

export default (props) => async (dispatch) => {

  confirm({
    title: 'Are you sure to delete this Lesson Block',
    content: 'Note: This action cannot be undone',
    okText: 'Yes, Delete it',
    onOk() {
      
      dispatch(deleteLessonBlock(props));
      
    }
  });
}