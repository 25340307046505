import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import Details from './Components/Details';

export default class extends React.PureComponent{
  
  static propTypes = {
    student: PropTypes.object.isRequired,

    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired
  }

  render () {

    const {
      student,
      onClickEdit,
      onClickDelete
    } = this.props;
    
    return (
      <Row>
    
        <Col span={12}>
          <h1>
            { student.first_name+' '.concat(student.last_name) }
          </h1>
        </Col>

        <Col span={12} className="text-right">
          
          <Button type="primary" onClick={onClickEdit}>
            Edit
          </Button>
          <Button type='danger' onClick={() => onClickDelete(student)} style={{ marginLeft: 10}}>
            Delete
          </Button>

        </Col>

        <Col span={24}>

          <Details student={student} />

        </Col>
        
      </Row>
    )
  }
}