import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

export default class extends React.PureComponent{

  static propTypes = {
    lessonBlocks: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    onChangeTable: PropTypes.func.isRequired
  }

  render () {
    
    const {
      lessonBlocks,
      pagination,
      onChangeTable
    } = this.props;

    const columns = [
      { 
        title: 'Name', 
        dataIndex: '',
        width: 200,
        render: (text, record) => (
          <NavLink to={`/portal/lesson_blocks/${record.id}/view`}>
            { `${record.student.first_name} ${record.student.last_name}` }
          </NavLink>
        )
      },

      {
        title: 'Title',
        dataIndex: 'title'
      },

      {
        title: 'Discipline',
        dataIndex: '',
        render: (text, record) => (
          <span>
            { record.lessons.length > 0 ? record.lessons[0].discipline.name : '--' }
          </span>
        )
      },

      {
        title: 'Tutor',
        dataIndex: '',
        render: (text, record) => (
          <span>
            {record.lessons.length > 0 ? `${record.lessons[0].tutor.first_name} ${record.lessons[0].tutor.last_name}` : '--'}
          </span>
        )
      },

      {
        title: 'Slot',
        dataIndex: '',
        render: (text, record) => {
          
          if ( record.lessons.length === 0) return null;
          
          let currentIndex = 0;
          const startOfWeek = moment().startOf('isoWeek');
          const endOfWeek = moment().endOf('isoWeek');

          record.lessons.forEach((lesson, index) => {
            if (moment(lesson.date).isBetween(startOfWeek, endOfWeek)) {
              currentIndex = index;
            }
          });
          
          if (record.lessons.length === (currentIndex - 1) ) {
            currentIndex = record.lessons.length;
          }
          
          return (
            <span>
              { `${record.lessons[currentIndex].day_of_week } @ ${record.lessons[currentIndex].time}`}
            </span>
          )
        }
      },

      {
        title: 'Duration',
        dataIndex: '',
        render: (text, record) => {
          const duration = record.lessons.length > 0 ? record.lessons[0].duration : 0;

          return (
            <span>
              { duration !== 0 ? `${duration} ${duration === '1' ? 'Hour': 'Minutes'}` : '--' }
            </span>
          )
        }
      },

      {
        title: 'Start Date',
        dataIndex: '',
        render: (text, record) => (
          <span>
            {record.lessons.length > 0 ? moment(record.lessons[0].date).format('DD MMM YYYY') : '--'}
          </span>
        )
      },

      {
        title: 'End Date',
        dataIndex: 'end_date',
        render: (text, record) => (
          <span>
            {record.lessons.length > 0 ? moment(record.lessons[record.lessons.length - 1].date).format('DD MMM YYYY') : '--'}
          </span>
        )
      },

      {
        title: 'Lessons',
        dataIndex: '',
        render: (text, record) => (
          <span>
            {record.lessons.length}
          </span>
        )
      }

    ];

    return (

      <Table 
        dataSource={lessonBlocks}
        columns={columns} 
        rowKey={record => record.id}
        onChange={onChangeTable}
        pagination={{          
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
      />

    )
  };
}