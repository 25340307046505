import { createAction, handleActions } from 'redux-actions';

const setShowLessonModal = createAction("SCREENS/LESSON_BLOCK_VIEW/SET_SHOW_LESSON_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowLessonModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowLessonModal, reducer };
