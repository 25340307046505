import { createAction, handleActions } from 'redux-actions';

const loadDisciplines = createAction("CORE/CLIENTS/LOAD_DISCIPLINES");

const defaultState = []

const reducer = handleActions(
  {
    [loadDisciplines]: (state, action) => action.payload
  },
  defaultState
);

export { loadDisciplines, reducer };