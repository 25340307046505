import * as React from 'react';
import PropTypes from 'prop-types';
import { Screen } from 'evolve-portal';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Details from './Components/Details';
import Table from './Components/Table';

import LessonBlockModal from '../../Modals/LessonBlock';
import LessonModal from '../../Modals/Lessons';


export default class extends React.PureComponent{

  static propTypes = {
    lessonBlock: PropTypes.object,
    lessonForEdit: PropTypes.object,
    showLessonBlockModal: PropTypes.bool.isRequired,
    showLessonModal: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onCloseLessonBlockModal: PropTypes.func.isRequired,
    didUpdateLessonBlock: PropTypes.func.isRequired,
    onCloseLessonModal: PropTypes.func.isRequired,
    didCreateLesson: PropTypes.func.isRequired,
    didUpdateLesson: PropTypes.func.isRequired
  }

  static defaultProps = {
    lessonBlock: null,
    lessonForEdit: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {
    const {
      lessonBlock,
      lessonForEdit,

      showLessonBlockModal,
      showLessonModal,
      onCloseLessonBlockModal,
      didUpdateLessonBlock,
      onCloseLessonModal,
      didCreateLesson,
      didUpdateLesson
    } = this.props;
    
    if (!lessonBlock) return null;

    return (
      <Screen>

        <Breadcrumbs lessonBlock={lessonBlock} />

        <Header />

        <Details lessonBlock={lessonBlock} />

        <Table />


        {showLessonBlockModal && (
          <LessonBlockModal
            lessonBlock={lessonBlock}
            onClose={onCloseLessonBlockModal}
            didUpdate={didUpdateLessonBlock}
          />
        )}

        {showLessonModal && (
          <LessonModal
            onClose={onCloseLessonModal}
            didCreate={didCreateLesson}
            didUpdate={didUpdateLesson}
            lessonBlock={lessonBlock}
            lesson={lessonForEdit}
          />
        )}
     

      </Screen>
    )
  };
}