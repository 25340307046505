import * as React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';

import LessonFormInput from  './LessonFormInput';

export default class extends React.PureComponent {

  static propTypes = {
    time: PropTypes.string.isRequired
  }

  render() {
    const { time } = this.props;

    return <FieldArray name="lessons" time={time} component={LessonFormInput} />

  };
}