import { setActiveTab } from '../State/activeTab';

import fetchTutorLessons from '../Services/fetchTutorLessons';

export default (activeTab) => async (dispatch) => {

  await dispatch(setActiveTab(parseFloat(activeTab)));

  dispatch(fetchTutorLessons());
  
}