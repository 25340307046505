import { combineReducers } from 'redux';

import Students from './Students/reducer';
import StudentEditor from './StudentEditor/reducer';
import StudentView from './StudentView/reducer';
import LessonBlocks from './LessonBlocks/reducer';
import LessonBlockView from './LessonBlockView/reducer';

import Timetable from './Timetable/reducer';

export default combineReducers({
  
  Students,
  StudentEditor,
  StudentView,
  LessonBlocks,
  LessonBlockView,
  Timetable
})
