/* eslint-disable prefer-destructuring */

import { message } from 'antd';
import { assign } from 'lodash';

import handleException from '../../../Core/Helpers/handleException';
import { createLessonBlock } from '../../../HttpRequests/lessonBlocks';
import { setIsFormFilledUp } from '../State/isFormFilledUp';

export default (values) => async (dispatch) => {
  
  try {

    const selectedLesson = [];
    
    values.lessons.forEach(lesson => {
      if (lesson.checked) {
        selectedLesson.push(lesson.date);
      }
    });

    const dates = values.dates.split(',');
    const data = assign({}, values, { lessons: selectedLesson });
    data.start_date = dates[0];
    data.end_date = dates[1];

    if (data.lessons.length > 0) {

      const response = await createLessonBlock({ data });

      const lessonBlock = response.data.data;
      
      message.success("Lesson Block Created")
      
      dispatch(setIsFormFilledUp(false));
      
      return lessonBlock;

    }

    message.error("Please select atleast one or more lesson");
    return false;
    

  } catch(error) {

    handleException(error);

    return false;

  }
}
