import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import { Field } from 'redux-form';
import { DatePickerField, TimePickerField, SwitchField, SelectField } from 'redux-form-antd-fields';

const labelColSpan = 5;
const wrapperColSpan = 18;

export default class extends React.PureComponent{

  static propTypes = {
    lessons: PropTypes.any.isRequired,
    submitting: PropTypes.bool.isRequired,
    dayOfWeek: PropTypes.string.isRequired,
    lesson: PropTypes.object
  }

  static defaultProps = {
    lesson: null
  }

  disabledDate = (current: any) => {

    const { lessons, dayOfWeek } = this.props;

    if (current) {
      for(let i = 0; lessons.length > i; i += 1){
        
        if (current.format('DD MMM YYYY') === moment(lessons[i].date).format('DD MMM YYYY')) {
          return true;
        }

      }
    }

    return current && (current.format('dddd') !== dayOfWeek);

  } 

  render() {
    const {
      submitting,
      lesson
    } = this.props;


    const durationOptions = [
      { label: '30 minutes', value: '30'},
      { label: '45 minutes', value: '45'},
      { label: '60 minutes', value: '1'},
    ]

    return (
      <div>
        
        <Field
          required
          component={DatePickerField}
          disabled={submitting}
          disabledDate={this.disabledDate}
          name="date"
          label="Date"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          required
          component={TimePickerField}
          disabled={submitting}
          name="time"
          label="Time"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
          displayFormat="HH:mm"
          minuteStep={15}
          defaultOpenValue={moment("16:00:00","HH:mm:ss")}
        />

        <Field
          required
          component={SelectField}
          disabled={submitting}
          name="duration"
          label="Duration"
          options={durationOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}

        />

        {lesson && (

          <Field
            required
            component={SwitchField}
            disabled={submitting}
            name="cancelled"
            label="Cancelled"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 3 }}

          />

        )}
        
      </div>
      
    )
  };
}
