export default (values) => {
  const errors = {};

  if (!values.title) errors.title = true;
  if (!values.discipline_id) errors.discipline_id = true;
  if (!values.tutor_id) errors.tutor_id = true;
  if (!values.dates) errors.dates = true;
  if (!values.day_of_week) errors.day_of_week = true;
  if (!values.time) errors.time = true;
  if (!values.duration) errors.duration = true;
  if (values && values.paid && !values.paid_method) errors.paid_method = true;
  if (values && values.paid && !values.paid_date) errors.paid_date = true;
  
  return errors;

};
