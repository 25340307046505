import { connect } from 'react-redux';
import Component from './Component';

import computeDimension from './Handlers/computeDimension';
import onClickViewDetails from './Handlers/onClickViewDetails';
import onClickNext from './Handlers/onClickNext';
import onClickPrev from './Handlers/onClickPrev';

const mapStateToProps = (state) => ({
  lessons: state.screens.Timetable.lessons,
  fetching: state.screens.Timetable.fetching,
  selectedMonth: state.screens.Timetable.selectedMonth,
  colDimensions: state.screens.Timetable.colDimensions
})

const actionCreators = {
  computeDimension,
  onClickViewDetails,
  onClickNext,
  onClickPrev
};

export default connect(mapStateToProps, actionCreators)(Component);