
import { combineReducers } from 'redux';

import LessonBlock from './LessonBlock/reducer';

export default combineReducers({
  
  LessonBlock
  
})
