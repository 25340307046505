import * as React from 'react';
import PropTypes from 'prop-types';

import Table from './Components/Table';
import Toolbar from './Components/Toolbar';

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object.isRequired,
    searchTerm: PropTypes.string.isRequired,
  
    onChangeSearch: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired
  }

  render() {

    const {
      student,
      searchTerm,
      onChangeSearch,
      onClickNew
    } = this.props;
  

    return (
      
      <React.Fragment>

        <Toolbar
          searchTerm={searchTerm}
          onChangeSearch={onChangeSearch}
          onClickNewLessonBlock={onClickNew}
        />

        <Table student={student} />

      </React.Fragment>
      
    )
  };
}