import { createAction, handleActions } from 'redux-actions';

const setSelectedLessons = createAction("SCREENS/LESSONS/SET_SELECTED_LESSON");
const clearSelectedLessons = createAction("SCREENS/LESSONS/CLEAR_SELECTED_LESSON");

const defaultState = [];

const reducer = handleActions(
  {
    [setSelectedLessons]: (state, action) => action.payload,
    [clearSelectedLessons]: () => defaultState
  },
  defaultState
);

export { setSelectedLessons, clearSelectedLessons, reducer };