import { createAction, handleActions } from 'redux-actions';

const loadStudents = createAction("SCREENS/STUDENTS/LOAD_STUDENTS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadStudents]: (state, action) => action.payload,    
  },
  defaultState
);

export { loadStudents, reducer };