import moment from 'moment';

import { setFilter, resetFilter } from '../State/filter'; 
import { setSelectedMonth, resetSelectedMonth} from '../State/selectedMonth';
import fetchTutorLessons from '../Services/fetchTutorLessons';

export default (date, dateString) => async (dispatch) => {  
  
  if (dateString === '') {
    
    dispatch(resetFilter());
    dispatch(resetSelectedMonth())

  } else {

    const firstDay = moment(dateString).startOf('isoWeek').format('YYYY-MM-DD');
    const lastDay = moment(dateString).endOf('isoWeek').format('YYYY-MM-DD');

    dispatch(setSelectedMonth(dateString));
    dispatch(setFilter(`${firstDay},${lastDay}`));
    
  }

  dispatch(fetchTutorLessons());

}