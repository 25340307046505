import { createAction, handleActions } from 'redux-actions';

const setActiveTab = createAction("SCREENS/STUDENT_VIEW/SET_ACTIVE_TAB");

const defaultState = 'LESSON_BLOCKS';

const reducer = handleActions(
  {
    [setActiveTab]: (state, action) => action.payload,
  },
  defaultState
);

export { setActiveTab, reducer };
