import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';

import { FORM_NAME } from '../Form';


const selectValues = state => getFormValues(FORM_NAME)(state);

export default createSelector(
  selectValues,
  (values) => {

    if (values && (values.time)) {
      
      return values.time;

    }
    
    return null;

  }
)