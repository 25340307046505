import { createAction, handleActions } from 'redux-actions';

const setShowLessonBlockModal = createAction("SCREENS/LESSON_BLOCK_VIEW/SET_SHOW_LESSON_BLOCK_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowLessonBlockModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowLessonBlockModal, reducer };
