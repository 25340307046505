import { reduxForm } from 'redux-form';

import Form from '../Components/LessonBlockFormNew';

import validate from './validate';
import onSubmit from './onSubmit';

import { FORM_NAME } from './index';

const formconfig = {

  form: FORM_NAME,
  validate,
  onSubmit,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
}

export default reduxForm(formconfig)(Form);
