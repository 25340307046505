import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import LessonBlockFormNew from './Form/lessonBlockFormNew';
import LessonBlockFormUpdate from './Form/lessonBlockFormUpdate';
import LessonForm from './Form/lessonsForm';

export default class extends React.PureComponent{

  static propTypes = {
    disciplines: PropTypes.array.isRequired,
    tutors: PropTypes.array.isRequired,
    lessonBlock: PropTypes.object,
    dayOfWeek: PropTypes.string,
    time: PropTypes.string,
    paid: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    isFormFilledUp: PropTypes.bool.isRequired,
  
    componentWillMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
    onClickBack: PropTypes.func.isRequired,
    onClickNext: PropTypes.func.isRequired,
    onChangePaid: PropTypes.func.isRequired
  }

  static defaultProps = {
    lessonBlock: null,
    dayOfWeek: null,
    time: null,
    paid: null
  }


  componentWillMount = () => this.props.componentWillMount(this.props);
  
  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      disciplines,
      tutors,
      lessonBlock,
      dayOfWeek,
      time,
      paid,
      submitting,
      isFormFilledUp,

      onClickCancel,
      onClickSubmit,
      onClickBack,
      onClickNext,
      onChangePaid

    } = this.props;
    
    return (
      <Modal
        title={`${lessonBlock ? 'Update Lesson Block': 'New Lesson Block'}`}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        footer={[
          <React.Fragment key="fragment">
            
            {/* New Lesson Block Buttons */}
            {(isFormFilledUp && !lessonBlock) && (
              <Button key="back" onClick={onClickBack}>
                Back
              </Button>
            )}

            {!isFormFilledUp && (
              <Button key="cancel" onClick={onClickCancel}>
                Cancel
              </Button>
            )}

            {(isFormFilledUp && !lessonBlock) && (
              <Button key="submit" type="primary" loading={submitting} onClick={onClickSubmit}>
                Create
              </Button>
            )}

            {/* New Lesson Block and Lessons Selection */}
            {(!isFormFilledUp && !lessonBlock) && (
              <Button key="next" type="primary" onClick={onClickSubmit} style={{ zIndex: 1}}>
                Next
              </Button>
            )}

            {/* Update Lesson Block Buttons */}
            {!isFormFilledUp && lessonBlock && (

              <Button key="update" type="primary" onClick={onClickSubmit} style={{ zIndex: 5}}>
                Update
              </Button>
              
            )}

          </React.Fragment> 
        ]}
        width={650}
      > 
        
        {/* New Lesson Block */}
        {!isFormFilledUp && !lessonBlock && (
          <LessonBlockFormNew
            disciplines={disciplines}
            tutors={tutors}
            onSubmit={onClickNext}
            dayOfWeek={dayOfWeek}
            paid={paid}
            onChangePaid={onChangePaid}
          />
        )}

        {/* New Lesson Block and Lessons Selection */}
        {isFormFilledUp && !lessonBlock && (
          <LessonForm time={time} />
        )}

        {/* Update Lesson Block */}
        {!isFormFilledUp && lessonBlock && (
          <LessonBlockFormUpdate
            disciplines={disciplines}
            tutors={tutors}
            dayOfWeek={dayOfWeek}
            paid={paid}
            lessonBlock={lessonBlock}
            onChangePaid={onChangePaid}
          />
        )}

      </Modal>
      
    )
  }
}

