import * as React from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import { Button } from 'antd';

import styles from './GroupHeader.css';

export default class extends React.PureComponent {

  static propTypes = {
    selectedMonth: PropTypes.string.isRequired,

    onClickNext: PropTypes.func.isRequired,
    onClickPrev: PropTypes.func.isRequired
  }

  render() {
    
    const {
      selectedMonth,

      onClickNext,
      onClickPrev
    } = this.props;


    const days = [];
    const currentMonths = [];

    const startDate = moment(selectedMonth);
    const startWeek = startDate.clone().startOf('isoWeek');
    const endWeek = startDate.clone().endOf('isoWeek');
    const date = startWeek.clone().subtract(1, 'day');
        
    while (endWeek > startWeek || startWeek.format('M') === endWeek.format('M')) {
      currentMonths.push(startWeek.format('MMMM YYYY'))
      startWeek.add(1,'month');
   }

    while (date.isBefore(endWeek, 'day')) {
      days.push(Array(7).fill(0).map(() => date.add(1, 'day').clone()))
    }
    
    return(

      <React.Fragment>
        <div className={styles.monthWrapper}>

          <div>
            <Button icon="arrow-left" onClick={onClickPrev} />
          </div>

          <div>

            <h2 style={{ textAlign: 'center' }}>
              {currentMonths.join(' - ')}
            </h2>

          </div>

          <div>

            <Button icon="arrow-right" onClick={onClickNext} />

          </div>

        </div>
      
        <div className={styles.weekWrappper}>

          <div className={styles.item} />
          
          {days[0].map((item, dayIndex) => (
            <div className={styles.item} key={String(dayIndex)}>
              {`${moment(item).format('dddd').toUpperCase()} ${moment(item).format('DD')}`}
            </div>  
          ))}

        </div>

      </React.Fragment>
      
    )
  }
}