import { message, } from 'antd';
import { push } from 'connected-react-router';

import handleException from '../../../Core/Helpers/handleException';
import { updateStudent } from '../../../HttpRequests/students';

export default ({ id, data }) => async (dispatch) => {

  try{

    await updateStudent({ id, data });
    message.success("Student Updated.");

    dispatch(push(`/portal/students/${id}/view`));

  } catch(error) {
    
    handleException(error);
    
  }
}