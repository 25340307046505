import * as React from 'react';
import PropTypes from 'prop-types'

import TimeColumn from './TimeColumn';
import DayCells from './DayCells';
import PlotLessons from './PlotLessons';
import styles from './GroupItem.css';

export default class extends React.PureComponent{

  static propTypes = {
    lessons: PropTypes.array.isRequired,
    colDimensions: PropTypes.any.isRequired,
    
    computeDimension: PropTypes.func.isRequired,
    onClickViewDetails: PropTypes.func.isRequired
  }

  componentDidMount = () => this.props.computeDimension();

  render() {

    const {
      lessons,
      colDimensions,
      onClickViewDetails
    } = this.props;

    return (
      
      <div className={styles.wrapper}>

        <div className={styles.table}>
          <TimeColumn />      
        </div>

        {[1,2,3,4,5,6,7].map((item, itemIndex) => (

          <div className={styles.table} data-col={itemIndex} key={String(itemIndex)}>

            <DayCells />

          </div>

        ))}
        
        <PlotLessons
          lessons={lessons}
          colDimensions={colDimensions}
          onClickViewDetails={onClickViewDetails}
        />

      </div>

    )
  };
}