import { createAction, handleActions } from 'redux-actions';

const loadLessonBlock = createAction("SCREENS/LESSON_BLOCK_VIEW/LOAD_LESSON_BLOCK");
const clearLessonBlock = createAction("SCREENS/LESSON_BLOCK_VIEW/CLEAR_LESSON_BLOCK");

const defaultState = null;

const reducer = handleActions(
  {
    [loadLessonBlock]: (state, action) => action.payload,
    [clearLessonBlock]: () => null
  },
  defaultState
);

export { loadLessonBlock, clearLessonBlock, reducer };