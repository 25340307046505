import * as React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Table, Col, Button, Input} from 'antd';

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object.isRequired,
    searchTerm: PropTypes.string.isRequired,
  
    onChangeSearch: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired
  }

  render() {
    const {
      student,
      searchTerm,
      onChangeSearch,
      onClickNew,
      onClickEdit
    } = this.props;

    const columns = [
      { 
        title: 'Name', 
        dataIndex: '',
        render: (text, record) => (
          <NavLink to="#" onClick={() => onClickEdit(record)}>
            { record.first_name+' '.concat(record.last_name) }
          </NavLink>
        )
      }, {
        title: 'Relationship', 
        dataIndex: 'relationship'
      }, {
        title: 'Email', 
        dataIndex: 'email'
      }, {
        title: 'Mobile', 
        dataIndex: 'mobile'
      }, {
        title: 'Note', 
        dataIndex: 'notes'
      }
    ];

    return (
      <React.Fragment>
  
        <Col span={12}>
  
          <Input.Search
            style={{ width: 250 }}
            placeholder="Search"
            onChange={onChangeSearch}
            value={searchTerm}
          />
  
        </Col>
  
        <Col span={12} className="text-right">
  
          <Button type="primary" onClick={onClickNew}>
            New NOK
          </Button>
  
        </Col>
  
        <Col span={24} className="m-t-15">
  
          <Table dataSource={student.next_of_kins} columns={columns} rowKey={record => record.id} />
          
        </Col>
  
      </React.Fragment>

      

    )
  };
}