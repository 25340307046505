import * as React from 'react';

import { getTimeInterval } from '../Utils';
import styles from './TimeColumn.css';

export default () => (

  <React.Fragment>

    { getTimeInterval(60).map((time, timeIndex) => (

      <div className={styles.cell} key={String(timeIndex)}>

        <div className={styles.time}>
          
          { time }

        </div>

      </div>
      
    ))}
    
  </React.Fragment>
)