import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import selectSelectedDayOfWeek from './Selectors/selectSelectedDayOfWeek';
import selectPaid from './Selectors/selectPaid';
import selectSelectedTime from './Selectors/selectSelectedTime';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';

import onClickNext from './Handlers/onClickNext';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onClickBack from './Handlers/onClickBack';
import onChangePaid from './Handlers/onChangePaid';

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
  isFormFilledUp: state.modals.LessonBlock.isFormFilledUp,
  disciplines: state.core.disciplines,
  tutors: state.core.tutors,
  dayOfWeek: selectSelectedDayOfWeek(state),
  paid: selectPaid(state),
  time: selectSelectedTime(state)
});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  onClickNext,
  onClickCancel,
  onClickSubmit,
  onClickBack,
  onChangePaid
};

export default connect(mapStateToProps, actionCreators)(Component);