import * as React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import moment from 'moment';
import { Field } from 'redux-form';
import { SwitchField } from 'redux-form-antd-fields'

const labelColSpan = 1;
const wrapperColSpan = 23;

export default class extends React.PureComponent{

  static propTypes = {
    time: PropTypes.string.isRequired,
    fields: PropTypes.any.isRequired,
    meta: PropTypes.object.isRequired
  }

  render() {
    
    const { time, fields, meta } = this.props;
    const { submitting } = meta;
    
    return (

      <div style={{ height: 480, overflow: 'auto'}}>
        <div>
          {fields.map((lessonItem, index) => (
                        
            <div key={String(index)} style={{position:'relative'}}>

              <Field
                component={SwitchField}
                disabled={submitting}
                name={`${lessonItem}.checked`}
                defaultChecked={fields.get(index).checked}
                renderAfter={()=> (
                  <div style={{ textAlign: 'left'}}>
                    {`${moment(fields.get(index).date).format('DD MMM YYYY')} @ ${time}`}
                  </div>
                )}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                fieldCol={{ span: 3 }}                
              />

              <Divider type="horizontal" style={{ margin: '10px 0'}} />
            </div>

          ))}
          
        </div>
      </div>

    )
  };
}