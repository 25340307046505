import * as React from 'react';
import PropTypes from 'prop-types';
import { Screen } from 'evolve-portal';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Table from './Components/Table';

export default class extends React.PureComponent{
  
  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,  
  }

  componentDidMount = () => this.props.componentDidMount();

  render() {
    
    return (

      <Screen>

        <Breadcrumbs />

        <Header />
        
        <Table />

      </Screen> 

    )
  }
}