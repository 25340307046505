import { createAction, handleActions } from 'redux-actions';

const setColDimensions = createAction("SCREENS/LESSONS/SET_COL_DIMENSION");
const clearColDimensions = createAction("SCREENS/LESSONS/CLEAR_COL_DIMENSION");

const defaultState = [];

const reducer = handleActions(
  {
    [setColDimensions]: (state, action) => action.payload,
    [clearColDimensions]: () => defaultState
  },
  defaultState
);

export { setColDimensions, clearColDimensions, reducer };