import * as React from 'react';
import PropTypes from 'prop-types'
import { Row, Col } from 'antd';

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object
  }

  static defaultProps = {
    student: null
  }

  render () {
    const {
      student
    } = this.props;

    return (
      <Row>
    
        <Col span="12">
          <h1>
            { student ? 'Update Student' : 'New Student' }
          </h1>
        </Col>
      </Row>
    )
  }
}