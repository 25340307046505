import { message } from 'antd';
import { deleteLesson } from '../../../HttpRequests/lessons';
import fetchLessonBlock from './fetchLessonBlock';
import handleException from '../../../Core/Helpers/handleException';

export default ({ id }) => async (dispatch) => {

  try {
    
    await deleteLesson({ id });

    dispatch(fetchLessonBlock());
    
    message.success("Lesson deleted")

  } catch (error) {

    handleException(error);

  }
}