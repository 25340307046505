import handleException from '../../../Core/Helpers/handleException';
import { getLessonBlocks } from '../../../HttpRequests/lessonBlocks';
import { loadLessonBlocks } from '../State/lessonBlocks';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { searchTerm, pagination } = getState().screens.LessonBlocks;

  try {
    
    const params = {};
    
    params.include = [
      'student',
      'lessons',
      'lessons.tutor',
      'lessons.discipline'
    ].join();
    
    params.sort =  '-id';
        
    params['page[size]'] = pagination.pageSize
    params['page[number]'] = pagination.current

    if (searchTerm) {
      params.search = searchTerm;
    }

    const response = await getLessonBlocks({ params });
    
    dispatch(loadLessonBlocks( response.data.data ));

    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

  } catch(error) {
    
    handleException(error);

  }
}
