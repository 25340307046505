import { combineReducers } from 'redux';

import { reducer as lessonBlock } from './State/lessonBlock';
import { reducer as showLessonBlockModal } from './State/showLessonBlockModal';
import { reducer as showLessonModal } from './State/showLessonModal';
import { reducer as lessonForEdit } from './State/lessonForEdit';

export default combineReducers({
  lessonBlock,
  showLessonBlockModal,
  showLessonModal,
  lessonForEdit
})
