import { connect } from 'react-redux';

import Component from './Component';

import selectStudent from './Selectors/selectStudent';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onCloseNextOfKinsModal from './Handlers/onCloseNextOfKinsModal';
import didCreateNextOfKins from './Handlers/didCreateNextOfKins';
import onChangeActiveTab from './Handlers/onChangeActiveTab';
import onCloseLessonBlockModal from './Handlers/onCloseLessonBlockModal';
import didCreateLessonBlock from './Handlers/didCreateLessonBlock';

const mapStateToProps = (state) => ({
  student: selectStudent(state),
  showNextOfKinsModal: state.screens.StudentView.showNextOfKinsModal,
  nextOfKinsForEdit: state.screens.StudentView.nextOfKinsForEdit,
  activeTab: state.screens.StudentView.activeTab,
  showLessonBlockModal: state.screens.StudentView.showLessonBlockModal
});

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseNextOfKinsModal,
  didCreateNextOfKins,
  onChangeActiveTab,
  onCloseLessonBlockModal,
  didCreateLessonBlock
};

export default connect(mapStateToProps, actionCreators)(Component);