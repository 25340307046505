import moment from 'moment';
import { createAction, handleActions } from 'redux-actions';

const setFilter = createAction("SCREENS/LESSONS/SET_FILTER");
const resetFilter = createAction("SCREENS/LESSONS/RESET_FILTER");

const defaultState = `${moment().startOf('isoWeek').format('YYYY-MM-DD')},${moment().endOf('isoWeek').format('YYYY-MM-DD')}`;

const reducer = handleActions(
  {
    [setFilter]: (state, action) => action.payload,
    [resetFilter]: () => defaultState
  },
  defaultState
);

export { setFilter, resetFilter, reducer };