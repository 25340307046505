import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';

import Details from './Components/Details';
import Table from './Components/Table';

export default class extends React.PureComponent{

  static propTypes = {
    selectedLessons: PropTypes.array.isRequired,

    componentWillMount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onChangeStatus: PropTypes.func.isRequired
  }

  componentWillMount = () => this.props.componentWillMount(this.props);


  render () {

    const {
      selectedLessons,
      onClickCancel,
      onChangeStatus
    } = this.props;
    
    return (
           
      <Modal
        title="Lesson Details"
        visible
        onCancel={onClickCancel}
        footer={[
          <Button onClick={onClickCancel} key="close">
            Close
          </Button>
        ]}
        width={1024}
      >
        <Details
          selectedLessons={selectedLessons}
        />
        
        <Table 
          selectedLessons={selectedLessons}
          onChangeStatus={onChangeStatus}
        />

      </Modal>

    )
  }
}