import { createAction, handleActions } from 'redux-actions';

const loadStudent = createAction("SCREENS/STUDENT_VIEW/LOAD_STUDENT");
const clearStudent = createAction("SCREENS/STUDENT_VIEW/CLEAR_STUDENT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadStudent]: (state, action) => action.payload,
    [clearStudent]: () => null
  },
  defaultState
);

export { loadStudent, clearStudent, reducer };