import * as React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button } from 'antd';
import { Field } from 'redux-form';

import { InputField, SelectField, DatePickerField, TextAreaField } from 'redux-form-antd-fields';

const labelColSpan = 5;
const wrapperColSpan = 18;

export default class extends React.PureComponent{

  static propTypes = {
    student: PropTypes.object,
    submitting: PropTypes.bool.isRequired,

    onClickCreate: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired
  }

  static defaultProps = {
    student: null
  }

  render() {
    const {
      student,
      submitting,

      onClickCreate,
      onClickCancel
    } = this.props;

    const genderOptions = [
      { label: 'Male', value: 'Male'},
      { label: 'Female', value: 'Female'}
    ]
    return (

      <Row>

        <Card>
          
          <Col span={12}>

            <Field
              required
              component={InputField}
              disabled={submitting}
              name="first_name"
              label="First Name"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              required
              component={InputField}
              disabled={submitting}
              name="last_name"
              label="Last Name"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              required
              component={DatePickerField}
              disabled={submitting}
              name="date_of_birth"
              label="Date of Birth"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Field
              required
              component={SelectField}
              disabled={submitting}
              name="gender"
              label="Gender"
              options={genderOptions}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Field
              component={InputField}
              disabled={submitting}
              name="school"
              label="School"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              component={InputField}
              disabled={submitting}
              name="email"
              label="Email"
              type="email"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              component={InputField}
              disabled={submitting}
              name="mobile"
              label="Mobile"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Field
              component={InputField}
              disabled={submitting}
              name="home"
              label="Home"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              component={TextAreaField}
              disabled={submitting}
              name="notes"
              label="Notes"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />             

          </Col>

          <Col span={12}>
            
            <Field
              component={InputField}
              disabled={submitting}
              name="address_line_1"
              label="Address Line 1"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              component={InputField}
              disabled={submitting}
              name="address_line_2"
              label="Address Line 2"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              component={InputField}
              disabled={submitting}
              name="address_line_3"
              label="Address Line 3"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
          
            <Field
              component={InputField}
              disabled={submitting}
              name="town_or_city"
              label="Town / City"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Field              
              component={InputField}
              disabled={submitting}
              name="county"
              label="County"
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Field
              component={InputField}
              disabled={submitting}
              name="postcode"
              label="Postcode"
              colon
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />            

          </Col>

          <Row style={{ clear: "both" }}>

            <Col span={5} offset={3}>

              <Button type="default" className="m-r-10" onClick={onClickCancel}>
                Cancel
              </Button>

              <Button type="primary" loading={submitting} onClick={onClickCreate}>
                { student ? 'Update': 'Create'}
              </Button>

            </Col>

          </Row>

        </Card>

      </Row>     

    )
  };
}