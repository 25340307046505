import * as React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import GroupHeader from './Components/GroupHeader';
import GroupItem from './Components/GroupItem';

export default class extends React.PureComponent{

  static propTypes = {
    lessons: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    selectedMonth: PropTypes.string.isRequired,
    colDimensions: PropTypes.array.isRequired,

    computeDimension: PropTypes.func.isRequired,
    onClickViewDetails: PropTypes.func.isRequired,
    onClickNext: PropTypes.func.isRequired,
    onClickPrev: PropTypes.func.isRequired
  }
  
  render() {
    const {
      lessons,
      fetching,
      selectedMonth,
      colDimensions,

      computeDimension,
      onClickViewDetails,
      onClickNext,
      onClickPrev
    } = this.props;

    if (!lessons) return null;

    return (
      <div style={{ marginTop: 50}}>

        <Spin spinning={fetching}>

          <GroupHeader
            selectedMonth={selectedMonth}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
          />

          <GroupItem
            lessons={lessons}
            colDimensions={colDimensions}
            computeDimension={computeDimension}
            onClickViewDetails={onClickViewDetails}
          />
      
        </Spin>

      </div>
    )
  };
}
