import { connect } from 'react-redux';

import Component from './Component';

import selectStudent from '../../Selectors/selectStudent';

import onClickEdit from './Handlers/onClickEdit';
import onClickDelete from './Handlers/onClickDelete';

const mapStateToProps = (state) => ({
  student: selectStudent(state)
});

const actionCreators = {
  onClickEdit,
  onClickDelete
};

export default connect(mapStateToProps, actionCreators)(Component);
