import { connect } from 'react-redux';

import Component from './Component';

import onClickNew from './Handlers/onClickNew';
import onClickEdit from './Handlers/onClickEdit';
import onClickDelete from './Handlers/onClickDelete';

const mapStateToProps = (state) => ({
  lessonBlock: state.screens.LessonBlockView.lessonBlock,
});

const actionCreators = {
  onClickNew,
  onClickEdit,
  onClickDelete
};

export default connect(mapStateToProps, actionCreators)(Component);
