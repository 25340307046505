import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'antd';

import styles from './Details.css';

export default class extends React.PureComponent{

  static propTypes = {
    selectedLessons: PropTypes.array.isRequired
  }

  render() {
    
    const { selectedLessons } = this.props;

    const lesson = selectedLessons[0];
    
    return (
      
      <Row>

        <Col span={12}>

          <div className={styles.detailWrapper}>

            <div className={styles.detailLabel}>
              Tutor :
            </div>

            <div className={styles.detailData}>

              { `${lesson.tutor.first_name} ${lesson.tutor.last_name}`}

            </div>

          </div>

          <div className={styles.detailWrapper}>

            <div className={styles.detailLabel}>
              Discipline :
            </div>

            <div className={styles.detailData}>

              { lesson.discipline.name }

            </div>

          </div>

          <div className={styles.detailWrapper}>

            <div className={styles.detailLabel}>
              Date :
            </div>

            <div className={styles.detailData}>

              { moment(lesson.date).format('DD MMM YYYY')}

            </div>

          </div>

          <div className={styles.detailWrapper}>

            <div className={styles.detailLabel}>
              Time :
            </div>

            <div className={styles.detailData}>

              { lesson.time }

            </div>

          </div>

          <br /> 

        </Col>

      </Row>
      
    )
  };
}