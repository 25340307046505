import * as React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Divider } from 'antd';
import { NavLink } from 'react-router-dom';

export default class extends React.PureComponent{

  static propTypes = {
    lessonBlock: PropTypes.object.isRequired
  }

  render () {

    const {
      lessonBlock
    } = this.props;

    const { Item } = Breadcrumb;

    return (
      <React.Fragment>

        <Breadcrumb separator="»">

          <Item>
            Home
          </Item>

          <Item>
            <NavLink to="/portal/lesson_blocks">
              Lessons
            </NavLink>
          </Item>

          <Item>
            {`${lessonBlock.student.first_name} ${lessonBlock.student.last_name}`}
          </Item>

        </Breadcrumb>
          
        <Divider />

      </React.Fragment>
    )
  };
}