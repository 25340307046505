import { change } from 'redux-form';
import { FORM_NAME } from '../Form';

export default (event: any, checked: boolean) => async (dispatch) => {
  
  if (!checked) {
    dispatch(change(FORM_NAME, 'paid_method', ''));
    dispatch(change(FORM_NAME, 'paid_date', ''));
  }
  
}