/* eslint-disable no-nested-ternary */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Table, Dropdown, Button, Menu, Icon } from 'antd';

export default class extends React.PureComponent{

  static propTypes = {
    selectedLessons: PropTypes.array.isRequired,
    onChangeStatus: PropTypes.func.isRequired
  }

  render() {

    const {
      selectedLessons,
      onChangeStatus
    } = this.props;

    const menu = (record, index) =>  (

      <Menu onClick={({ key }) => onChangeStatus(key, record.id, index)}>

        <Menu.Item key='PENDING'>
          <Icon type="clock-circle" theme="filled" style={{ color: '#666' }} />
          Pending
        </Menu.Item>

        <Menu.Item key='ATTENDED'>
          <Icon type="check-circle" theme="filled" style={{ color: 'rgba(20, 190, 113, 1)' }} />
          Attended
        </Menu.Item>

        <Menu.Item key='CANCELLED'>
          <Icon type="close-circle" theme="filled" style={{ color: 'rgba(232, 71, 84, 1)' }} />
          Cancelled
        </Menu.Item>

      </Menu>
    );

    const column = [

      {
        title: 'Student',
        dataIndex: '',
        width: 200,
        render: (text, record) => (
          <span>
            {`${record.student.first_name} ${record.student.last_name}`}
          </span>
        )
      },

      {
        title: 'Duration',
        dataIndex: 'duration',
        width: 200,
        render: (text) =>  (
          <span>
            {`${text} ${text === 1 ? 'Hour': 'Minutes'}`}
          </span>
        )
        
      },
      
      {
        title: 'Status',
        dataIndex: '',
        width: 200,
        render: (text, record, index) => (

          <Dropdown
            overlay={menu(record, index)}
            trigger={['click']}
          >

            <Button style={{ width: 140 }}>

              {!record.attended && !record.cancelled && (
                <span>
                  <Icon type="clock-circle" theme="filled" style={{ color: '#666', marginRight: 5, fontSize: 16 }} />
                  Pending
                </span>
              )}

              {!!record.attended && !record.cancelled && (
                <span>
                  <Icon type="check-circle" theme="filled" style={{ color: 'rgba(20, 190, 113, 1)', marginRight: 5, fontSize: 16 }} />
                  Attended
                </span>
              )}

              {!record.attended && !!record.cancelled && (
                <span>
                  <Icon type="close-circle" theme="filled" style={{ color: 'rgba(232, 71, 84, 1)', marginRight: 5, fontSize: 16 }} />
                  Cancelled
                </span>
              )}

              <Icon type="caret-down" />

            </Button>
            
          </Dropdown>

        )
      }

    ]
    
    return (     

      <Table
        columns={column}
        dataSource={selectedLessons}
        rowKey={record => record.id}
        pagination={false}
      />

    )
  };
}