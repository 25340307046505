import moment from 'moment';
import { findIndex } from 'lodash';

export const getColDimension = () => {
  const columnDimensions = [];
  
  for (let i = 0; i < 8; i += 1) {
    
    const left = (i === 0) ? 0 : (columnDimensions[i - 1].left + columnDimensions[i - 1].width);

    let columnWidth = 0;
    
    const activeTab = document.querySelectorAll('.ant-tabs-tabpane-active');
    const columnElement = activeTab[0].querySelectorAll(`[data-col='${i}']`)[0];
    
    if (columnElement) {

      columnWidth = columnElement.getBoundingClientRect().width;

    }

    columnDimensions.push({
      left,
      width: columnWidth,
    });

  }
  
  return columnDimensions;

};

export const getTimeInterval = (interval) => {

  const startTime = moment('07:00 AM', 'hh:mm A');
  const endTime = moment('11:00 PM', 'hh:mm A');

  if(endTime.isBefore(startTime) ){
    endTime.add(1, 'day');
  }

  const timeInterval = [];

  while(startTime <= endTime){
    timeInterval.push(moment(startTime).format('hh:mm A'));
    startTime.add(interval || 15, 'minutes');
  }

  return timeInterval;

}


export const getNumberOfCells = (time) => findIndex(getTimeInterval(15), (interval) =>  interval === time )