import immutable from 'immutable';

import handleException from '../../../Core/Helpers/handleException';
import { updateLesson } from '../../../HttpRequests/lessons';
import { getProps } from '../props';

export default (value, id, index) => async (dispatch, getState) => {

  try {

    const { didUpdate } = getProps();
    
    let selectedLessons = immutable.fromJS(getState().screens.Timetable.selectedLessons);
  
    const data = {}
    switch (value) {
      default :
      break;

      case 'PENDING':
        selectedLessons = selectedLessons.setIn([index, 'attended'], 0);
        selectedLessons = selectedLessons.setIn([index, 'cancelled'], 0);
        data.attended = 0;
        data.cancelled = 0;
      break;

      case 'ATTENDED':
        selectedLessons = selectedLessons.setIn([index, 'attended'], 1);
        selectedLessons = selectedLessons.setIn([index, 'cancelled'], 0);
        data.attended = 1;
        data.cancelled = 0;
      break;

      case 'CANCELLED':
        selectedLessons = selectedLessons.setIn([index, 'attended'], 0);
        selectedLessons = selectedLessons.setIn([index, 'cancelled'], 1);      
        data.attended = 0;
        data.cancelled = 1;
      break;
    }
    
    selectedLessons = selectedLessons.toJS();

    await updateLesson({id, data});

    didUpdate(selectedLessons);
  
  } catch(error) {

    handleException(error);

  }
}