import handleException from '../../../Core/Helpers/handleException';
import { getProps } from '../props';
import { getStudent } from '../../../HttpRequests/students';
import { loadStudent } from '../State/student';

export default () => async (dispatch) => {
  try{
    
    const { id } = getProps().match.params;

    const params = {};
    params.include = [
      'nextOfKins',
      'lessonBlocks.lessons',
      'lessonBlocks.lessons.tutor',
      'lessonBlocks.lessons.discipline'
    ].join();

    const response = await getStudent({ id, params });

    dispatch(loadStudent(response.data.data))

  } catch(error) {

    handleException(error)

  }
}