import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Screens/Home';
import Students from './Screens/Students';
import StudentEditor from './Screens/StudentEditor';
import StudentView from './Screens/StudentView';
import LessonBlocks from './Screens/LessonBlocks';
import LessonBlockView from './Screens/LessonBlockView';

import Timetable from './Screens/Timetable';

export default () => (
  <Switch>
    
    <Route path="/portal/" exact component={Home} />

    <Route path="/portal/students" exact component={Students} />
    <Route path="/portal/students/new" exact component={StudentEditor} />
    <Route path="/portal/students/:id/view" exact component={StudentView} />
    <Route path="/portal/students/:id/edit" exact component={StudentEditor} />
    
    <Route path="/portal/lesson_blocks" exact component={LessonBlocks} />
    <Route path="/portal/lesson_blocks/:id/view" exact component={LessonBlockView} />

    <Route path="/portal/timetable" exact component={Timetable} />
    
  </Switch>
);