import * as React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import moment from 'moment';

import {
  InputField,
  DatePickerField,
  DateRangePickerField,
  TimePickerField,
  SelectField,
  TextAreaField,
  SwitchField
} from 'redux-form-antd-fields';


const labelColSpan = 5;
const wrapperColSpan = 18;

export default class extends React.PureComponent{

  static propTypes = {
    disciplines: PropTypes.array.isRequired,
    tutors: PropTypes.array.isRequired,
    submitting: PropTypes.bool.isRequired,
    dayOfWeek: PropTypes.string,
    paid: PropTypes.bool,
    lessonBlock: PropTypes.object,
    onChangePaid: PropTypes.func.isRequired
  }

  static defaultProps = {
    dayOfWeek: null,
    paid: null,
    lessonBlock: null,
  }

  disabledDate = (current: any) => {

    const { dayOfWeek } = this.props;
    
    return current && current.format('dddd') !== dayOfWeek
    
  }

  render() {
    const {
      disciplines,
      tutors,
      submitting,
      dayOfWeek,
      paid,
      lessonBlock,
      onChangePaid
    } = this.props;

    const dayOfWeekOption = [
      { label: 'Monday', value: 'Monday' },
      { label: 'Tuesday', value: 'Tuesday' },
      { label: 'Wednesday', value: 'Wednesday' },
      { label: 'Thursday', value: 'Thursday' },
      { label: 'Friday', value: 'Friday' },
      { label: 'Saturday', value: 'Saturday' },
      { label: 'Sunday', value: 'Sunday' }
    ];

    const disciplinesOption = disciplines.map(item => ({
      label: item.name,
      value: item.id
    }));

    const tutorsOption = tutors.map(item => ({
      label: `${item.first_name} ${item.last_name}`,
      value: item.id
    }));

    const durationOptions = [
      { label: '30 minutes', value: '30'},
      { label: '45 minutes', value: '45'},
      { label: '60 minutes', value: '1'},
    ]

    const paymentOptions = [
      { label: 'Card', value: 'Card' },
      { label: 'Cash', value: 'Cash' },
      { label: 'Bank Transfer', value: 'Bank Transfer'}
    ]
    
    return (
      <div>

        <Field
          required
          component={InputField}
          disabled={submitting}
          name="title"
          label="Title"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />
        
        <Field
          required
          component={SelectField}
          disabled={submitting}
          name="discipline_id"
          label="Discipline"
          placeholder="Select Discipline"
          options={disciplinesOption}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />
        
        <Field
          required
          component={SelectField}
          disabled={submitting}
          name="tutor_id"
          label="Tutor"
          placeholder="Select Tutor"
          options={tutorsOption}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          required
          component={SelectField}
          disabled={submitting || lessonBlock !== null}
          name="day_of_week"
          label="Day of Week"
          placeholder="Select Day of Week"
          options={dayOfWeekOption}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          required
          component={TimePickerField}
          disabled={submitting || lessonBlock !== null}
          name="time"
          label="Time"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
          displayFormat="HH:mm"
          minuteStep={15}
          defaultOpenValue={moment("16:00:00","HH:mm:ss")}
        />

        <Field
          required
          component={SelectField}
          disabled={submitting || lessonBlock !== null}
          name="duration"
          label="Duration"
          options={durationOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          required
          component={DateRangePickerField}
          disabled={submitting || !dayOfWeek || lessonBlock !== null}
          disabledDate={this.disabledDate}
          name="dates"
          label="Date"          
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={SwitchField}
          name="paid"
          label="Paid"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 3 }}
          onChange={onChangePaid}
        />

        <Field
          required={paid}
          component={SelectField}
          disabled={submitting || !paid}
          name="paid_method"
          label="Payment Method"
          placeholder="Select Payment Method"
          options={paymentOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          required={paid}
          component={DatePickerField}
          disabled={submitting || !paid}
          name="paid_date"
          label="Paid Date"
          placeholder="Select Paid Date"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
          
        />
        
        <Field
          component={TextAreaField}
          disabled={submitting}
          name="notes"
          label="Notes"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />
                
      </div>
      
    )
  };
}
