import { combineReducers } from 'redux';

import { reducer as tutors } from './State/tutors';
import { reducer as activeTab } from './State/activeTab';
import { reducer as filter } from './State/filter';
import { reducer as lessons } from './State/lessons';
import { reducer as fetching } from './State/fetching';
import { reducer as selectedMonth } from './State/selectedMonth';
import { reducer as colDimensions } from './State/colDimensions';
import { reducer as selectedLessons } from './State/selectedLessons';
import { reducer as showLessonDetailsModal } from './State/showLessonDetailsModal';

export default combineReducers({
  tutors,
  activeTab,
  filter,
  lessons,
  fetching,
  selectedMonth,
  colDimensions,
  selectedLessons,
  showLessonDetailsModal
})
