import fetchStudent from '../Services/fetchStudent';

export default (props) => async (dispatch) => {
  
  const { id } = props.match.params;

  if (id) {
    dispatch(fetchStudent({ id }));
  }

}