import { connect } from 'react-redux';
import Component from './Component';

import onClickNew from './Handlers/onClickNew';
import onClickEdit from './Handlers/onClickEdit';
import onChangeSearch from './Handlers/onChangeSearch';

const mapStateToProps = (state) => ({
  student: state.screens.StudentView.student,
  searchTerm: state.screens.StudentView.searchTerm,
});

const actionCreators = {
  onClickNew,
  onClickEdit,
  onChangeSearch
};

export default connect(mapStateToProps, actionCreators)(Component);