import { connect } from 'react-redux';

import Component from './Component';

import onChangeTable from './Handlers/onChangeTable';

const mapStateToProps = (state) => ({
  students: state.screens.Students.students,
  pagination: state.screens.Students.pagination
});

const actionCreators = {
  onChangeTable
};

export default connect(mapStateToProps, actionCreators)(Component);
