import * as React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

export default class extends React.PureComponent {

  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  componentDidMount = () => this.props.dispatch(push('/portal/timetable'));

  render = () => <div />
  
}