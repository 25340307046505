import { createAction, handleActions } from 'redux-actions';

const loadNextOfKinsForEdit = createAction("SCREENS/STUDENT_VIEW/LOAD_NEXT_OF_KINS_FOR_EDIT");
const clearNextOfKinsForEdit = createAction("SCREENS/STUDENT_VIEW/CLEAR_NEXT_OF_KINS_FOR_EDIT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadNextOfKinsForEdit]: (state, action) => action.payload,
    [clearNextOfKinsForEdit]: () => null
  },
  defaultState
);

export { loadNextOfKinsForEdit, clearNextOfKinsForEdit, reducer };