import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import Form from './Form';

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    lesson: PropTypes.object,
    lessonBlock: PropTypes.object.isRequired,

    componentWillMount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    lesson: null
  }

  componentWillMount = () => this.props.componentWillMount(this.props);


  render () {

    const {
      submitting,
      lessonBlock,
      onClickCancel,
      onClickSubmit,
      lesson
    } = this.props;
    
    const { lessons } = lessonBlock;
    const dayOfWeek = lessons[0].day_of_week;

    return (    
    
      <Modal
        title={`${lesson ? 'Update Lesson': 'New Lesson'}`}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={`${lesson ? 'Update' : 'Create'}`}
      >

        <Form
          lessons={lessons}
          lesson={lesson}
          dayOfWeek={dayOfWeek}
        />

      </Modal>

    )
  }
}