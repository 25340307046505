import { connect } from 'react-redux';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import onClickCancel from './Handlers/onClickCancel';
import onChangeStatus from './Handlers/onChangeStatus';

const mapStateToProps = () => ({});

const actionCreators = {
  componentWillMount,
  onClickCancel,
  onChangeStatus
};

export default connect(mapStateToProps, actionCreators)(Component);