import { connect } from 'react-redux';

import Component from './Component';

import onChangeTable from './Handlers/onChangeTable';

const mapStateToProps = (state) => ({
  lessonBlocks: state.screens.LessonBlocks.lessonBlocks,
  pagination: state.screens.LessonBlocks.pagination
});

const actionCreators = {
  onChangeTable
};

export default connect(mapStateToProps, actionCreators)(Component);
