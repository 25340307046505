import moment from 'moment';
import { createAction, handleActions } from 'redux-actions';

const setSelectedMonth = createAction("SCREENS/LESSONS/SET_SELECTED_MONTH");
const resetSelectedMonth = createAction("SCREENS/LESSONS/RESET_SELECTED_MONTH");

const defaultState = moment().format('YYYY-MM-DD');

const reducer = handleActions(
  {
    [setSelectedMonth]: (state, action) => action.payload,
    [resetSelectedMonth]: () => defaultState
  },
  defaultState
);

export { setSelectedMonth, resetSelectedMonth, reducer };
