import handleException from '../../../Core/Helpers/handleException';

import { getTutors } from '../../../HttpRequests/tutors';
import { loadTutor } from '../State/tutors';
import { setActiveTab } from '../State/activeTab';

import fetchTutorLessons from './fetchTutorLessons';

export default () => async (dispatch) => {

  try {

    const response = await getTutors();

    dispatch(setActiveTab(response.data.data[0].id));
    dispatch(loadTutor(response.data.data));
    dispatch(fetchTutorLessons())

  } catch(error) {

    handleException(error);

  }
}