import * as React from 'react';

import { getTimeInterval } from '../Utils';
import styles from './DayCells.css';

export default () => (

  <React.Fragment>

    { getTimeInterval(60).map((time, timeIndex)=> (

      <div className={styles.cell} key={String(timeIndex)} />
      
    ))}

  </React.Fragment>
)