import * as React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Button, Row } from 'antd'

export default class extends React.PureComponent{

  static propTypes = {
    searchTerm: PropTypes.string.isRequired,

    onChangeSearch: PropTypes.func.isRequired,
    onClickNewLessonBlock: PropTypes.func.isRequired
  }

  render() {

    const {
      searchTerm,
      onChangeSearch,
      onClickNewLessonBlock
    } = this.props;

    return (

      <Row style={{ marginBottom: 15 }}>
        
        <Col span={12}>

          <Input.Search
            style={{ width: 250 }}
            placeholder="Search"
            onChange={onChangeSearch}
            value={searchTerm}
          />

        </Col>

        <Col span={12} className="text-right">

          <Button type="primary" onClick={onClickNewLessonBlock}>
            New Lesson Block
          </Button>

        </Col>

      </Row>
    )
  }
}