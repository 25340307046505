import { clearNextOfKinsForEdit } from '../State/nextOfKinsForEdit';

import fetchStudent from '../Services/fetchStudent';

export default () => async (dispatch, getState) => {
  
  const { id } = getState().screens.StudentView.student;

  dispatch(fetchStudent({ id }));

  dispatch(clearNextOfKinsForEdit())
  
}