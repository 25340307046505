import { setProps } from '../props';
import fetchDisciplines from '../../../Core/Services/fetchDisciplines';
import fetchTutors from '../../../Core/Services/fetchTutors';

import initialize from '../Services/initialize';

export default (props: any) => async (dispatch) => {

  setProps(props);

  await dispatch(fetchDisciplines());
  await dispatch(fetchTutors());

  dispatch(initialize());
}