import { connect } from 'react-redux';

import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';

const mapStateToProps = (state) => ({
  student: state.screens.StudentEditor.student
});

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
};

export default connect(mapStateToProps, actionCreators)(Component);
