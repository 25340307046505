import { message } from 'antd';
import handleException from '../../../Core/Helpers/handleException';

import { updateNextofKin } from '../../../HttpRequests/nextOfKins';

export default (values) => async () => {
  try {
    
    const data = {...values};

    await updateNextofKin({ id: values.id, data });

    message.success("Next of Kins Updated.")

    return true;

  } catch(error) {
    
    handleException(error);

    return false;
  }
}