import * as React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField, SelectField, TextAreaField } from 'redux-form-antd-fields';

const labelColSpan = 5;
const wrapperColSpan = 18;

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired
  }

  render() {

    const { submitting } = this.props;

    const relationshipOptions = [
      { label: 'Mother', value: 'Mother'},
      { label: 'Father', value: 'Father'},
      { label: 'Guardian/Carer', value: 'Guardian/Carer'},
      { label: 'Grandparent', value: 'Grandparent'}
    ]

    return (
      <div>
        
        <Field
          required
          component={InputField}
          disabled={submitting}
          name="first_name"
          label="First Name"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          required
          component={InputField}
          disabled={submitting}
          name="last_name"
          label="Last Name"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />


        <Field
          required
          component={SelectField}
          disabled={submitting}
          name="relationship"
          label="Relationship"
          options={relationshipOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />
        
        <Field
          component={InputField}
          disabled={submitting}
          name="email"
          label="Email"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          component={InputField}
          disabled={submitting}
          name="mobile"
          label="Mobile"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />

        <Field
          component={TextAreaField}
          disabled={submitting}
          name="notes"
          label="Notes"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          
        />
        
      </div>
      
    )
  };
}
