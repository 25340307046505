import { createAction, handleActions } from 'redux-actions';

const loadLessonBlocks = createAction("SCREENS/LESSON_BLOCKS/LOAD_LESSON_BLOCKS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadLessonBlocks]: (state, action) => action.payload,    
  },
  defaultState
);

export { loadLessonBlocks, reducer };