import moment from 'moment';

import { setFilter } from '../../../State/filter';
import { setSelectedMonth } from '../../../State/selectedMonth';
import fetchTutorLessons from '../../../Services/fetchTutorLessons';

export default () => async (dispatch, getState) => {

  const date = moment(getState().screens.Timetable.selectedMonth).add('1', 'week');
  const startWeek = date.clone().startOf('isoWeek').format('YYYY-MM-DD');
  const endWeek = date.clone().endOf('isoWeek').format('YYYY-MM-DD');

  dispatch(setSelectedMonth(date.format('YYYY-MM-DD')));

  dispatch(setFilter(`${startWeek},${endWeek}`));
  
  dispatch(fetchTutorLessons());

}