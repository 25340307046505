import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { lessonBlock, lesson } = getProps();
  
  if (!lesson) {

    const lessons = lessonBlock.lessons[0];

    dispatch(initialize(FORM_NAME, {
      ...lessons,
      duration: '30'
    }));
  } else {
    
    dispatch(initialize(FORM_NAME, lesson));

  }
}